import React from "react";
import StackedPhonesAnimation from "../StackedPhonesAnimation";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  padding: 32px 0 0;
  max-height: 80%;
  ${(props)=>props.theme.displayPropsMobile && props.theme.displayPropsMobile}

  @media (${mediaMedium}) {
    width: 640px;
    position: absolute;
    left: 50%;
    top: 80px;
    height: 100vw;
    max-height: calc(732px + 2%);
    ${(props)=>props.theme.displayProps && props.theme.displayProps}
  };

  @media (min-width: 975px) {
    left: 45%;
    ${(props)=>props.theme.displayPropsMedia && props.theme.displayPropsMedia}
  };

  @media (min-width: 1034px) {
    max-height: calc(732px + 12%);
  };
`;

const ShopsLanding = ({theme}) => {
  return (
    <Wrapper theme={theme}>
      <StackedPhonesAnimation staticWrapper={true} />
    </Wrapper>
  );
};

export default ShopsLanding;
