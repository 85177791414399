import React from 'react'
import styled from 'styled-components'
import {graphql, Link, useStaticQuery} from 'gatsby'
import HeroSectionExtraHeading from '../HeroSectionExtraHeading'
import HeroSection from '../HeroSection'
import HeroMetricsThree from '../HeroMetricsThree'
import {black1, brandColor2, white} from '../styles/Colors'

import HeroBlueCTA from '../HeroBlueCTA'
import {FadeInRight, FadeInLeftSmall} from '../styles/animations'
import AgencyBrandsContentAnimation from '../AgencyBrandsContentAnimation'
import HeroTwoColumns from '../HeroTwoColumns'
import AnimatedLineSvg from '../AnimatedLineSvg'
import PagePadding from '../styles/PagePadding'
import HeroContentSnippet from '../HeroContentSnippet'
import ArrowButton from '../ArrowButton'
import ShopsLanding from "../brands/ShopsLanding";

// Images
import sponsoredDesktop from '../../images/33-sponsored-content-desktop.png'
import sponsoredMobile from '../../images/33-sponsored-content-mobile.png'
import DecorationRightLarge from "../snippets/DecorationRightLarge";
import HorizontalPhonesAnimation from "../HorizontalPhonesAnimation";
import AnimationWrapper from "../AnimationWrapper";
import {mediaMedium} from "../styles/variables";
import ResponsiveImages from "../styles/ResponsiveImages";

const Row = styled.span`
  display: block;
  margin-top: 24px;
`

const sponsoredContentImages = {
  desktop: {
    src: sponsoredDesktop,
    width: 1299,
    height: 1628,
  },
  mobile: {
    src: sponsoredMobile,
    width: 375,
    height: 404,
  },
}

const Line = styled.span`
  @media (${mediaMedium}) {
    position: absolute;
    z-index: 2;
    width: 30%;
    height: 100%;
    top: 26px;
    left: 26.5%;
    bottom: 0;
  }
`;

const InvertGrid = styled.div`
  order: 1;


  @media (${mediaMedium}) {
    order: 0;
  };
`;

const CenterImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;
  }
`;

const BrandsStackAgencyDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      logoTestimonial: file(
        relativePath: { eq: "66-testimonial-vincero.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "67-testimonial-brandads-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "67-testimonial-brandads-media.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentOne: file(relativePath: { eq: "58-brands-shop-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentTwo: file(relativePath: { eq: "58-brands-shop-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopMetrics: file(relativePath: { eq: "10-metric-2x.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileMetrics: file(relativePath: { eq: "10-metric.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fixedFeeOne: file(
        relativePath: { eq: "stackagency/03-display-oportunities-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fixedFeeTwo: file(
        relativePath: { eq: "stackagency/03-display-oportunities-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      caseStudyDesktop: file(
        relativePath: { eq: "stackagency/02-social-media.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      caseStudyMobile: file(
        relativePath: { eq: "stackagency/02-social-media.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      newslettersDesktop: file(
        relativePath: { eq: "stackagency/02-newsletter-inclusion-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      newslettersMobile: file(
        relativePath: { eq: "stackagency/02-newsletter-inclusion-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
            marketingOne: file(
        relativePath: { eq: "stackagency/04-agency-marketing-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketingTwo: file(
        relativePath: { eq: "stackagency/04-agency-marketing-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      marketingThree: file(
        relativePath: { eq: "stackagency/04-agency-marketing-3.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <>
      <HeroSectionExtraHeading
        heading="Your StackAgency"
        outline="Toolkit"
        top="01"
        title="SPONSORED CONTENT "
        backgroundColor={brandColor2}
        alt={true}
        images={sponsoredContentImages}
        AnimationComponent={AgencyBrandsContentAnimation}
        theme={{
          backgroundColor: white,
          titleMarginDesktop: '306px',
          titleWidth: '200px',
          imageMarginMedia: '-12% 0 0',
          imageLeft: '45%',
          imageTop: 'initial',
          imageBottom: '0',
          paddingMedia: '91px 0 0',
        }}
      >
        Dedicated articles and roundup inclusions for your specific product or
        service, curated from publishers and content creators that best fit your
        brand.
      </HeroSectionExtraHeading>

      <HeroTwoColumns
        align="center"
        background={black1}
        desktopGap="0px !important"
        gap="80px"
      >
        <InvertGrid>
          <AnimationWrapper component={FadeInLeftSmall}>
            <CenterImage>
              <ResponsiveImages
                images={[
                  {
                    fluid: images.caseStudyDesktop.childImageSharp.fluid
                  },
                  {
                    fluid: images.caseStudyMobile.childImageSharp.fluid
                  }
                ]}
              />
            </CenterImage>
          </AnimationWrapper>
        </InvertGrid>

        <PagePadding>
          <HeroSection
            top="02"
            title="SOCIAL MEDIA "
            theme={{
              imageTop: '64px',
              paddingMedia: '50px 0 0',
              paddingDesktop: '262px 40px 270px',
              overflowWidth: '1000',
              contentMarginBottom: "54px"
            }}
            noGrid
            alt
          >
            Posts on various social media platforms to drive traffic and support
            editorial strategy, ensuring the right audience is exposed to your
            content for optimized engagement opportunities.
          </HeroSection>
        </PagePadding>
      </HeroTwoColumns>


      <HeroTwoColumns
        align="center"
        desktopGap="0px !important"
      >
        <PagePadding>
          <HeroSection
            title="NEWSLETTER INCLUSIONS"
            top="03"
            theme={{
              paddingDesktop: "164px 0 331px",
              contentMarginBottom: "54px"
            }}
            noGrid
          >
            Publisher newsletters land in millions of inboxes every day. An
            inclusion for your brand can help you reach exponentially more readers
            and consumers than ever before.
          </HeroSection>
        </PagePadding>
        <AnimationWrapper component={FadeInRight}>
          <CenterImage>
            <ResponsiveImages
              images={[
                {
                  fluid: images.newslettersDesktop.childImageSharp.fluid
                },
                {
                  fluid: images.newslettersMobile.childImageSharp.fluid
                }
              ]}
            />
          </CenterImage>
        </AnimationWrapper>
      </HeroTwoColumns>

      <HeroSection
        title="DISPLAY OPPORTUNITIES"
        top="04"
        alt
        reverse
        backgroundColor={brandColor2}
        theme={{
          paddingDesktop: "213px 0 199px",
          contentMarginBottom: "54px"
        }}
        displayComponent={
          <HorizontalPhonesAnimation
            theme={{
              width: "450px",
              leftTransform: "calc(57% + 31px)",
              rightTransform: "calc(124% + 32px)",
              positionLarge: "translateX(-75%) translateY(-180%)",
              positionMedium: "translateX(-80%) translateY(-180%)",
              positionSmall: "translateX(-100%) translateY(-210%)",
              rightTop: "5px",
              middleTop: "5px",
              leftMediaWidth: "150%",
              rightMediaWidth: "150%",
              leftMediaTransform: "-18%",
              rightMediaTransform: "-16%",
              rightMediaTop: "-4%",
              rightWidth: "89.8%",
              cardStyles: "margin-top: -4%;",
              cardDesktopStyles: "margin-top: 0;"
            }}
            images={{
              one: images.fixedFeeOne,
              three: images.fixedFeeTwo
            }}
          />
        }
      >
        Feature your brand with premium placement and a strong call-to-action on
        high-traffic article pages on select publisher websites.
      </HeroSection>

      <HeroSection
        noPadding={true}
        theme={{
          paddingMedia: '0 0 0',
          paddingDesktop: '0 0 0'
        }}
        noTextContent
        displayComponent={
          <Line>
            <AnimatedLineSvg
              duration={1000}
              triggerOffset={50}
              theme={{displayProps: "left : 110%; transform: rotateY(180deg);"}}
            >
              <DecorationRightLarge/>
            </AnimatedLineSvg>
          </Line>
        }
      >
        <HeroContentSnippet
          title="STACK IT UP"
          padding={'0 0 200px'}
          noBreak
        >
          Want more ways to earn? Add on other StackCommerce solutions and
          watch your revenue stack up.
        </HeroContentSnippet>
      </HeroSection>

      <HeroSection
        top="01"
        title="FLAT RATE & CPC "
        theme={{
          paddingMedia: '180px 0 93px',
          paddingDesktop: '180px 0 93px',
          marginTop: "-180px !important",
          backgroundColor: "transparent",
          contentMarginBottom: "54px"
        }}
        displayComponent={
          <ShopsLanding
            theme={{
              displayPropsMobile: '' +
                'height: 80vw;' +
                'margin-top: 15%;',
              displayProps: '' +
                'top: -30px;' +
                'left: 51.1%;' +
                'height: 100vw;' +
                'margin-top: 0;',
              displayPropsMedia: '' +
                'top: -30px;' +
                'height: 100vw;' +
                'left: 51.1%;',
            }}
          />
        }
      >
        Flat-rate and CPC-based performance content solutions deliver premium
        articles, marketing email and newsletter features, and native ad
        placements at scale.
        <Row>
          <ArrowButton
            attrs={{as: Link, to: '/brands/stack-media/'}}
          >
            Find Out More
          </ArrowButton>
        </Row>
      </HeroSection>

      <HeroSection
        backgroundColor={brandColor2}
        title="WHITELISTING"
        top="02"
        theme={{
          paddingDesktop: "215px 0 201px",
          contentMarginBottom: "54px"
        }}
        reverse
        alt
        contentWrapper="div"
        displayComponent={
          <HorizontalPhonesAnimation
            theme={{
              width: "450px",
              middleTransform: "calc(60% + 31px)",
              rightTransform: "calc(127% + 32px)",
              positionLarge: "translateX(-75%) translateY(-180%)",
              positionMedium: "translateX(-80%) translateY(-180%)",
              positionSmall: "translateX(-100%) translateY(-210%)",
              rightTop: "6px",
              middleTop: "5px",
              leftMediaWidth: "150%",
              rightMediaWidth: "150%",
              leftMediaTransform: "-18%",
              rightMediaTransform: "-16%",
              rightMediaTop: "-4%",
              rightWidth: "89.8%",
              cardStyles: "margin-top: -4%;",
              cardDesktopStyles: "margin-top: 0;"
            }}
            images={{
              one: images.marketingOne,
              two: images.marketingTwo,
              three: images.marketingThree
            }}
          />
        }
      >
        Our high-performing media brands and marketplaces specialize in
        performance content, strategic traffic-driving tactics, second-presence
        SEM campaigns, and more.

        <Row>
          <ArrowButton
            variant="white"
            attrs={{
              href: "mailto:agency@stackcommerce.com?subject=STACKAGENCY%20For%20Publishers",
              as: 'a',
            }}
          >
            Find Out More
          </ArrowButton>
        </Row>
      </HeroSection>

      <HeroMetricsThree
        backgroundMedia={images.mobileMetrics}
        backgroundDesktop={images.desktopMetrics}
        content={[
          {
            number: '60',
            suffix: '%',
            description: 'Of the Comscore Top 25 Publishers Use StackCommerce',
          },
          {
            number: '40',
            suffix: '%',
            description: 'Of Revenue Comes From <br/> Repeat Purchasers',
          },
          {
            number: '1',
            suffix: 'k+',
            description: 'Publishers in Our Network',
          },
        ]}
      />

      <HeroBlueCTA
        title="GET REAL RESULTS"
        outline="WITH STACK"
        description="See how StackAgency can help you reach your target audiences on the sites they visit daily."
        linkLabel="Get Started"
        link={{
          href: "mailto:agency@stackcommerce.com?subject=STACKAGENCY%20For%20Publishers",
          as: 'a',
          label: 'Get Started'
        }}
      />
    </>
  )
}

export default BrandsStackAgencyDefer
